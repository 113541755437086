import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import PrivateOutlet from "./auth/PrivateOutlet";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import LostPassword from "./components/LostPassword/LostPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateOutlet />}>
          <Route path="" element={<Home />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/lostpassword" element={<LostPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}
