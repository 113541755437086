import { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

import LogoRow from "../Shared/LogoRow/LogoRow";
import ControlledInput from "../Shared/ControlledInput/ControlledInput";

type LostPasswordType = {
  email: string;
};

export default function LostPassword() {
  const [resultState, setResultState] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL;
  const schema = yup
    .object({
      email: yup.string().email().required(),
    })
    .required();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });
  const onSubmit = (data: LostPasswordType) => {
    apiUrl &&
      axios
        .post(`${apiUrl}/users/lostpassword`, {
          email: data.email,
        })
        .then(function (response) {
          setResultState("success");
          setResultMessage(t(response.data.message));
        })
        .catch(function (error) {
          setResultState("danger");
          setResultMessage(t(error.message));
        });
  };
  return (
    <Container>
      <LogoRow />
      <Row>
        <Col
          md={{
            offset: 3,
            size: 6,
          }}
          sm="12"
        >
          {resultMessage && (
            <Alert color={resultState}>{t(resultMessage)}</Alert>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ControlledInput
              name="email"
              type="email"
              required={true}
              translation="global.email"
              control={control}
              errors={errors}
            />
            <Button>{t("lost_password.submit")}</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
